import React from "react";
import { styled } from "styled-components";
import Topbar from "../components/modules/TopBar";
import Header from "../components/modules/Header";
import About from "../components/modules/About";
import Hobbies from "../components/modules/Hobbies";
import Exp from "../components/modules/Exp";
import Projects from "../components/modules/Projects";
import Skills from "../components/modules/Skills";
import Footer from "../components/modules/Footer";
import Cookies from "../components/atoms/Cookies";

const HomeWrapper = styled.div``;

const Home = ({ lang, handleLanguageChange }) => {

  return (
    <HomeWrapper>
      <Topbar lang={lang} handleLanguageChange={handleLanguageChange} />
      <Header />
      <About />
      <Exp />
      <Projects />
      <Skills />
      <Hobbies />
      <Footer />
      <Cookies />
    </HomeWrapper>
  );
};

export default Home;
